import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const InfographicBlogStyled = styled.div`
    color: ${props => props.theme.colors.secondaryGrey};
    max-width: 800px;
    margin: 0 auto;
    width: 100%;

    .infographic-img {
        width: auto;
        height: 205px;
        background: url(${props => props.infographicImg}) no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
        @media screen and (min-width: 768px) {
            height: 426px;
            margin-bottom: 3rem;
        }
    }

    p strong {
        text-transform: uppercase;
    }
    p b {
        text-transform: capitalize;
    }
    & h3 {
        margin: 1rem 0;
        text-align: left;
        font-size: 1.25rem;
        line-height: 1.8rem;
        @media screen and (min-width: 768px) {
            margin: 1.5rem 0;
            font-size: 1.5rem;
            line-height: 2.18rem;
        }
    }
    .post-border {
        content: '';
        height: 5px;
        width: 139px;
        background-color: ${props => props.theme.colors.primaryYellow};
    }

    & article {
        margin: 0.5rem 0;
        font: 0.85rem/1.65rem ${props => props.theme.fonts[0]}, sans-serif;
        & + p {
            margin: 1.5rem 0;
            font: 0.85rem/1.25rem ${props => props.theme.fonts[0]}, sans-serif;
            color: ${props => props.theme.colors.grey};
        }

        @media screen and (min-width: 768px) {
            font: 1.125rem/1.87rem ${props => props.theme.fonts[0]}, sans-serif;
        }
    }
`;

function InfographicBlog({ ...props }) {
    const blogContent = props && props.blogContent;
    return (
        <InfographicBlogStyled
            infographicImg={
                blogContent.largeImage && blogContent.largeImage.length > 0
                    ? blogContent.largeImage[0].image
                    : blogContent.smallImage &&
                      blogContent.smallImage.length > 0
                    ? blogContent.smallImage[0].image
                    : ''
            }
        >
            <div className="infographic-img" />
            <p>
                <strong>{blogContent.contentTypeLabel}</strong> |{' '}
                {blogContent.publicationDate}
            </p>
            <h3>{blogContent.title}</h3>
            <div className="post-border"></div>
            <article>{blogContent.body && parse(blogContent.body)}</article>
        </InfographicBlogStyled>
    );
}

InfographicBlog.propTypes = {
    blogContent: PropTypes.shape({
        body: PropTypes.string,
        title: PropTypes.string.isRequired,
        largeImage: PropTypes.array,
        smallImage: PropTypes.array,
        contentTypeLabel: PropTypes.string.isRequired,
        publicationDate: PropTypes.string.isRequired,
    }),
};

export default InfographicBlog;
