import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BlogNavStyled = styled.button`
    color: ${props => props.theme.colors.primaryYellow};
    font: bold 1rem ${props => props.theme.fonts[1]};
    letter-spacing: 0;
    line-height: 1.437rem;
    text-transform: capitalize;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        margin: 0 20px;
    }
    i {
        display: inline-block;
        border: solid ${props => props.theme.colors.primaryYellow};
        border-width: 0 3px 3px 0;
        width: 8.89px;
        height: 8.89px;
    }

    .previous-blog-nav {
        transform: rotate(-230deg);
        -webkit-transform: rotate(-230deg);
        margin-right: 13px;
        position:relative;
        left:2px;
    }
    .next-blog-nav {
        transform: rotate(-40deg);
        -webkit-transform: rotate(-40deg);
        margin-left: 13px;
        position:relative;
        right:2px;
    }
`;
function BlogNav({ ...props }) {
    return (
        <BlogNavStyled
            className={
                props.disabled ? 'isDisabled ' + props.variant : props.variant
            }
            onClick={props.clickHandler}
            style={{ float: props.name === 'BACK' ? 'none' : props.iconPos }}
        >
            {props.iconPos && props.iconPos === 'left' ? (
                <React.Fragment>
                    <i className={props.variant}></i>
                    {props.name}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {props.name} <i className={props.variant}></i>
                </React.Fragment>
            )}
        </BlogNavStyled>
    );
}

BlogNav.propTypes = {
    disabled: PropTypes.bool,
    variant: PropTypes.string.isRequired,
    iconPos: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    clickHandler: PropTypes.func.isRequired,
};

export default BlogNav;
