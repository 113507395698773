import React, { useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useGlobal from '../store';

const PaginationStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;

    .pagination-container {
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .previous,
    .next {
        display: flex;
        flex-direction: row;
        color: ${props => props.theme.colors.primaryGray};
        font: 600 1rem/1.1875rem ${props => props.theme.fonts[0]};
        cursor: pointer;
    }

    .previous {
        margin-right: 28px;
    }

    .next {
        margin-left: 28px;
    }

    .page-number-container {
        display: flex;
    }

    .page-number {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 12px 0 12px;
        color: #212721;
        font: 500 1.125rem/1.375rem ${props => props.theme.fonts[0]};
        cursor: pointer;
        overflow: visible;
        width: 5px;
    }

    .page-number:hover {
        font-weight: 700;
    }

    .page-number-underline {
        display: none;
        color: ${props => props.theme.colors.primaryYellow};
        background: ${props => props.theme.colors.primaryYellow};
        height: 2px;
        width: 13px;
        border: 0;
    }

    .selected .page-number-underline {
        display: flex;
    }

    .selected {
        font-weight: 700;
    }

    .faded {
        color: #b3b3b3 !important;
    }

    .arrow {
        border: 1px solid ${props => props.theme.colors.primaryGray};
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        margin: 6px 8px auto 8px;
    }

    .faded .arrow {
        border-color: #b3b3b3 !important;
    }

    .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }

    .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    .show-more {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 43px;
        width: 190px;
        border-radius: 3px;
        background-color: ${props => props.theme.colors.primaryYellow};
        color: ${props => props.theme.colors.primaryGray};
        font: 500 0.875rem/1.25rem ${props => props.theme.fonts[1]};
        margin: auto;
        cursor: pointer;
    }

    .faded-button {
        background-color: #b3b3b3;
    }

    @media screen and (min-width: 768px) {
        .pagination-container {
            display: flex;
        }

        .show-more {
            display: none;
        }
    }
`;

const Pagination = props => {
    const [totalPages, setTotalPages] = useState(10);
    const [concurrentPages, setConcurrentPages] = useState(false);
    const [useEffectTrigger, triggerUseEffect] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const [currentPage, setCurrentPage] = useState(
        globalState.contentFilter?.activePage != null
            ? globalState.contentFilter?.activePage
            : props.currentPage
    );

    useEffect(() => {
        var contentFilter = {
            activePage: currentPage,
            pageSize: globalState?.contentFilter?.pageSize ?? 9,
            activeDaysFilter: globalState?.contentFilter?.activeDaysFilter ?? 0,
            activeTypeFilters:
                globalState?.contentFilter?.activeTypeFilters ?? null,
            mobileShowMoreActive: concurrentPages,
            forceApiCall: concurrentPages, // All "show more" calls require new data regardless of the filter state
        };

        globalActions.services.getFilteredBlogsService(contentFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, concurrentPages, useEffectTrigger]);

    if (
        globalState.totalPages != null &&
        totalPages !== globalState.totalPages
    ) {
        setTotalPages(globalState.totalPages);
    }

    const changePage = pageNumber => {
        setConcurrentPages(false);
        setCurrentPage(pageNumber);
        if (
            globalState.contentFilter?.activePage != null &&
            globalState.contentFilter.activePage !== pageNumber &&
            currentPage === pageNumber
        ) {
            triggerUseEffect(!useEffectTrigger);
        }
    };

    const nextPage = () => {
        if (currentPage !== totalPages) {
            setConcurrentPages(false);
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage !== 1) {
            setConcurrentPages(false);
            setCurrentPage(currentPage - 1);
        }
    };

    const showMoreMobile = () => {
        globalState.contentFilter.pageSize += 9;
        setConcurrentPages(true);
        setCurrentPage(1);
        triggerUseEffect(!useEffectTrigger);
    };

    return (
        <PaginationStyled>
            <div className="pagination-container">
                <div
                    className={
                        globalState.contentFilter?.activePage !== 1
                            ? 'previous'
                            : 'previous faded'
                    }
                >
                    <i className="arrow left" />
                    <div
                        className="previous-label"
                        onClick={() => previousPage()}
                    >
                        PREV
                    </div>
                </div>

                <div className="page-number-container">
                    {Array.from(Array(totalPages), (e, i) => {
                        return (
                            <div
                                key={i}
                                className={
                                    globalState.contentFilter?.activePage ===
                                    i + 1
                                        ? 'page-number selected'
                                        : 'page-number'
                                }
                                onClick={() => changePage(i + 1)}
                            >
                                <div>{i + 1}</div>
                                <hr className="page-number-underline" />
                            </div>
                        );
                    })}
                </div>

                <div
                    className={
                        globalState.contentFilter?.activePage !== totalPages
                            ? 'next'
                            : 'next faded'
                    }
                >
                    <div className="next-label" onClick={() => nextPage()}>
                        NEXT
                    </div>
                    <i className="arrow right" />
                </div>
            </div>
            <div
                className={
                    globalState.contentFilter?.pageSize >=
                        globalState.totalPosts ||
                    globalState.contentFilter?.activePage ===
                        globalState.totalPages
                        ? 'show-more faded-button'
                        : 'show-more'
                }
                onClick={
                    globalState.contentFilter?.pageSize >=
                        globalState.totalPosts ||
                    globalState.contentFilter?.activePage ===
                        globalState.totalPages
                        ? null
                        : () => showMoreMobile()
                }
            >
                {props.buttonText}
            </div>
        </PaginationStyled>
    );
};

Pagination.propTypes = {
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    buttonText: PropTypes.string,
};

Pagination.defaultProps = {
    totalPages: 10,
    currentPage: 1,
    buttonText: 'SHOW MORE',
};

export default withSize()(Pagination);
