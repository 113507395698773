import React, { useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import styled from 'styled-components';
import useGlobal from '../store';

const RelatedArticlesStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    a {
        color: ${props => props.theme.colors.secondaryGrey} !important;
    }

    a:hover {
        color: ${props => props.theme.colors.primaryYellow} !important;
    }

    .related-articles-header {
        font: 700 0.875rem/1.0625rem ${props => props.theme.fonts[0]};
        margin: 0 0 24px 0;
    }

    .article-container {
        display: flex;
        flex-direction: row;
        margin: 0 0 24px 0;
        width: 100%;
        height: 90px;
    }

    .final-article {
        border-bottom: 0 !important;
    }

    .article-header {
        display: flex;
        flex-direction: row;
        font: 600 0.875rem/1.33rem ${props => props.theme.fonts[0]};
        margin: 45px auto 25px 0px;
        color: ${props => props.theme.colors.secondaryGrey};
    }

    .article-date {
        font-weight: 300;
        margin-left: 5px;
    }

    .article-title {
        color: black;
        font: 400 0.875rem/1.3125rem ${props => props.theme.fonts[0]};
        margin: 0 0 0 16px;
        color: ${props => props.theme.colors.secondaryGrey};
    }

    .article-title:hover {
        color: ${props => props.theme.colors.primaryYellow};
        text-decoration: underline;
    }

    .article-thumbnail {
        height: 89px;
        width: 151px;
    }

    @media screen and (min-width: 1024px) {
        margin: 0 0 72px 0;
        .related-articles-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            max-width: 800px;
            margin: auto;
        }

        .articles-container {
            display: flex;
            flex-direction: row;
        }

        .article-container {
            display: flex;
            flex-direction: column;
            width: 250px;
            height: auto;
            margin: 0 auto 0 0;
        }

        .article-thumbnail {
            height: 160px;
            width: 250px;
            max-width: 250px;
            max-height: 160px;
            margin: 0 0 16px 0;
        }

        .article-title {
            margin: 0;
            font: 500 1rem/1.5rem ${props => props.theme.fonts[0]};
        }

        .final-article {
            margin-right: 0;
        }
    }
`;

const RelatedArticles = props => {
    const [articles, setArticles] = useState();
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        async function getRelatedArticles() {
            await globalActions.services.getRelatedBlogs();
        }
        if (
            globalState.selectedBlog != null &&
            globalState.selectedBlog !== ''
        ) {
            getRelatedArticles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalState.selectedBlog]);

    if (globalState.relatedBlogs == null) {
        return <RelatedArticlesStyled></RelatedArticlesStyled>;
    } else {
        return (
            <RelatedArticlesStyled>
                <div className="related-articles-container">
                    <div className="related-articles-header">RELATED POSTS</div>
                    <div className="articles-container">
                        {globalState.relatedBlogs.map(
                            (article, articleIndex) => (
                                <div
                                    key={articleIndex}
                                    className={
                                        articleIndex === 2
                                            ? 'article-container final-article'
                                            : 'article-container'
                                    }
                                >
                                    {article.videoUrl != null &&
                                    article.videoUrl !== '' ? (
                                        <iframe
                                            src={
                                                article.videoUrl.indexOf('?') <
                                                0
                                                    ? article.videoUrl +
                                                      '?modestbranding=1'
                                                    : article.videoUrl
                                            }
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            className="article-thumbnail"
                                            title='picture in picture'
                                        ></iframe>
                                    ) : (
                                        <img
                                            src={article.thumbnail[0]}
                                            title={article.title}
                                            alt={article.title}
                                            className="article-thumbnail"
                                        />
                                    )}
                                    <div className="article-title">
                                        <a href={'/blog/' + article.slug}>
                                            {article.title}
                                        </a>
                                    </div>
                                </div>
                            ),
                            this
                        )}
                    </div>
                </div>
            </RelatedArticlesStyled>
        );
    }
};

export default withSize()(RelatedArticles);
