import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { navigate } from '@reach/router';
import useGlobal from '../../store';
const BlogCardStyled = styled.article`
    &,
    * {
        box-sizing: border-box;
    }
    position: relative;
    width: 100%;
    height: 484px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin: 1rem 0;

    &:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
    }

    @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
        margin: 1rem auto;
    }

    &:not(.featured) {
        max-width: 365px;
    }

    &.featured {
        background: ${props => props.theme.colors.secondaryGrey};
        color: ${props => props.theme.colors.white};

        @media screen and (min-width: 768px) {
            flex-wrap: wrap;
            flex-direction: row;
            height: 364px;
        }
    }

    & figure {
        width: 100%;
        min-height: 180px;
        position: relative;
        overflow: hidden;

        .featured& {
            min-height: 257px;
        }

        & > img {
            width: 100%;
            max-height: 187px;
            [class$='infographic']& {
                height: 257px;
                max-height: 257px;
            }
            .featured& {
                max-height: 100%;
                height: 100%;
            }
        }
    }

    & .post__details {
        width: 100%;
        padding: 2rem;
        max-width: 624px;
        @media screen and (min-width: 768px) {
            max-width: 397px;
            .container .featured& {
                padding: 2rem;
            }
        }
        @media screen and (min-width: 768px) {
            .featured& {
                padding: 4.3rem 5.6rem;
                display: flex;
                flex-direction: column;
            }
        }

        & > .post__title {
            position: relative;
            .featured& {
                h4,
                h5 {
                    color: ${props => props.theme.colors.white};
                }
            }
            @media screen and (min-width: 768px) {
                .featured& {
                    h4 {
                        line-height: 31.8px;
                        font-weight: normal;
                        max-height: 120px;
                    }
                }
            }
            & > * {
                text-align: left;
                &:first-child {
                    font-weight: bold;
                    font-size: 0.87rem;
                    font-family: ${props => props.theme.fonts[0]};
                    color: ${props => props.theme.colors.secondaryGrey};
                    small {
                        &:before {
                            content: '';
                            position: absolute;
                            width: 5px;
                            top: 6px;
                            left: -2px;
                            height: 10px;
                            border-right: 1px solid
                                ${props => props.theme.colors.secondaryGrey};
                        }
                        font-weight: normal;
                        font-size: 0.87rem;
                        position: relative;
                        text-transform: capitalize;
                    }
                }
                &:last-child {
                    font-size: 1.125rem;
                    padding: 1.5rem 0;
                    line-height: 1.51rem;
                    font-weight: 500;
                    font-family: ${props => props.theme.fonts[1]};
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    max-height: 98px;
                    color: ${props => props.theme.colors.secondaryGrey};
                }
            }
            &:after {
                content: '';
                position: absolute;
                height: 5px;
                width: 139px;
                background-color: ${props => props.theme.colors.primaryYellow};
                bottom: -20px;
            }
        }

        & .post__content {
            margin-top: 2.5rem;
            height: 50px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: ${props => props.theme.colors.textGrey};
            .featured& {
                color: ${props => props.theme.colors.white};
            }
        }
    }
`;

const VideoPostStyled = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    & > iframe {
        width: 100%;
        height: 257px;
        .featured & {
            height: 100%;
        }
    }
`;

const BlogCard = props => {
    const [globalState] = useGlobal();
    const {
        postID,
        postSlug,
        postCategory,
        postDate,
        isFeatured,
        hasImage,
        postImgURL,
        hasVideo,
        postVideoURL,
        postTitle,
        previewText,
        wraperClass,
        blog,
    } = props;
    //Format permalink and redirect to blogviewer page
    function getPermalink(slug, id, category, selectedBlog) {
        let permalink = '/blog/' + slug;
        globalState.selectedBlog = selectedBlog;
        navigate(permalink, { replace: false });
    }

    return (
        <BlogCardStyled
            onClick={_ => getPermalink(postSlug, postID, postCategory, blog)}
            className={`${isFeatured ? 'featured' : 'card'} ${
                wraperClass ? wraperClass : ''
            } card__post post__${postID} ${postSlug} cat__${postCategory}`}
        >
            <figure>
                {hasImage ? (
                    <img src={postImgURL} title={postTitle} alt={postTitle} />
                ) : hasVideo ? (
                    <VideoPostStyled>
                        <iframe
                            src={postVideoURL}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title='picture-in-picture'
                        ></iframe>
                    </VideoPostStyled>
                ) : (
                    ''
                )}
            </figure>
            <div className="post__details">
                <hgroup className="post__title">
                    <h5>
                        {`${isFeatured ? 'Featured ' : ''}  
                        ${postCategory}`}{' '}
                        &nbsp;
                        <small> &nbsp; {postDate}</small>
                    </h5>
                    <h4>{postTitle}</h4>
                </hgroup>
                {postCategory.toLowerCase() === 'article' ||
                postCategory.toLowerCase() === 'case study' ? (
                    <p className="post__content">
                        {previewText && parse(previewText)}
                    </p>
                ) : (
                    ''
                )}
            </div>
        </BlogCardStyled>
    );
};

BlogCard.propTypes = {};

export default BlogCard;
