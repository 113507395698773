import React, { useState, useEffect } from 'react';
import { withSize } from 'react-sizeme';
import SEO from '../SEO';
import ArticleBlogStyled from './ArticleBlog';
import ArticleLargeImageBlog from './ArticleLargeImageBlog';
import InfographicBlog from './InfographicBlog';
import VideoBlog from './VideoBlog';
import BlogNav from '../UI/BlogNav';
import SocialShare from './SocialShare';
import { navigate } from '@reach/router';
import useGlobal from '../../store';
import PropTypes from 'prop-types';
import {
    applyContentLabels,
    getIndexOfBlog,
} from '../../services/BlogUtilitiesService';
import RelatedArticles from '../RelatedArticles';

import styled from 'styled-components';

const BlogViewerStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    max-width: calc(100% - 40px);

    @media screen and (min-width: 768px) {
        max-width: calc(100% - 96px);
        margin: 0 48px;
    }

    @media screen and (min-width: 1256px) {
        max-width: 1160px;
        margin: auto;
    }

    .previous-next-social-container,
    .back-nav {
        margin-top: 40px;
        margin-bottom: 48px;
        @media screen and (max-width: 768px) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    .previous-next-social-container {
        padding-bottom: 56px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 800px;
        margin: 2rem auto;
        @media screen and (max-width: 767px) {
            padding-bottom: 40px;
        }
        .isDisabled {
            cursor: not-allowed;
            opacity: 0.5;
            display: inline-block; /* For IE11/ MS Edge bug */
            pointer-events: none;
            text-decoration: none;
        }
    }
    .social-share {
        margin: 0 auto;
        padding-bottom: 40px;
        @media screen and (min-width: 768px) {
            margin: 0;
        }
    }

    .article-content {
        max-width: 800px;
        margin: auto;
        &.large .blog-content-image {
            @media screen and (min-width: 1366px) {
                transform: scale(1.45);
                margin: 6rem 0;
            }
        }

        & .blog-content-image {
            position: relative;
            width: 100%;
            margin: 2rem 0;
        }

        & .blog-content-heading {
            position: relative;
            color: ${props => props.theme.colors.secondaryGrey};
            font: normal 1.8rem/2.68rem ${props => props.theme.fonts[1]};
            margin: 3rem 0 1rem 0;
            text-align: left;
            &:after {
                content: '';
                position: absolute;
                width: 139px;
                height: 5px;
                background-color: ${props =>
                    props.theme.colors.secondaryYellow};
                top: 4rem;
                left: 0;
            }

            @media screen and (max-width: 767px) {
                font-size: 1.5rem;
                line-height: 2rem;
                margin: 1rem 0;
                &:after {
                    top: 3rem;
                }
            }
        }

        & .blog-content-link {
            color: ${props => props.theme.colors.secondaryYellow};
            font-weight: bold;
            font-size: 1.25rem;
        }
    }
`;

const BlogViewer = ({ ...props }) => {
    const [globalState, globalActions] = useGlobal();
    const [currentBlog, setCurrentBlog] = useState();
    const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);
    const [isPrevBtnDisabled, setIsPrevBtnDisabled] = useState(false);

    function navigateback() {
        navigate('/blog', { replace: true });
    }

    useEffect(() => {
        const getSlug = window.location.pathname.split('/');
        if (getSlug[1] === 'blog' && getSlug[2]) {
            if (globalState.status !== 'SUCCESS' && !globalState.selectedBlog) {
                (async () => {
                    let postDetails = await globalActions.services.getPostBySlug(
                        getSlug[2]
                    );
                    let labelFilterData = [];
                    labelFilterData[0] = postDetails;
                    let filterLabelData = await applyContentLabels(
                        labelFilterData
                    );
                    setCurrentBlog(filterLabelData[0]);
                })();
            } else {
                setCurrentBlog(globalState.selectedBlog);
            }
        }

        const currentPostIndex = getIndexOfBlog(
            globalState.selectedBlog,
            globalState.blogPosts
        );
        const isPrevButtonEnabled =
            globalState.contentFilter?.activePage === 1 && currentPostIndex === 0;
        const isNextButtonEnabled =
            currentPostIndex === globalState.blogPosts.length - 1 &&
            globalState.contentFilter?.activePage === globalState.totalPages;

        setIsPrevBtnDisabled(isPrevButtonEnabled);
        setIsNextBtnDisabled(isNextButtonEnabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentBlog, globalState.selectedBlog]);

    const { size } = props;

    function ShowNextBlog(e) {
        e.preventDefault();

        var currentBlogs = globalState.blogPosts;
        var currentBlogIndex = currentBlogs
            ? getIndexOfBlog(globalState.selectedBlog, globalState.blogPosts)
            : -1;

        if (currentBlogIndex === currentBlogs.length - 1) {
            var nextPageFilter = { ...globalState.contentFilter };
            nextPageFilter.activePage++;
            globalActions.services.loadNextBlogList(nextPageFilter, 'Next');
        } else if (
            currentBlogIndex >= 0 &&
            currentBlogIndex < currentBlogs.length - 1
        ) {
            globalState.selectedBlog = currentBlogs[currentBlogIndex + 1];
            setCurrentBlog(globalState.selectedBlog);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; //For chrome
            let permalink = '/blog/' + globalState.selectedBlog.slug;
            navigate(permalink, { replace: false });
        } else {
            if (
                globalState.relatedBlogs != null &&
                globalState.relatedBlogs.length > 0
            ) {
                globalState.selectedBlog = globalState.relatedBlogs[0];

                setCurrentBlog(globalState.selectedBlog);

                document.body.scrollTop = 0; // For Safari

                document.documentElement.scrollTop = 0; //For chrome

                let permalink = '/blog/' + globalState.selectedBlog.slug;

                navigate(permalink, { replace: false });
            }
        }
    }

    function ShowPreviousBlog(e) {
        e.preventDefault();
        var currentBlogs = globalState.blogPosts;
        var currentBlogIndex = getIndexOfBlog(
            globalState.selectedBlog,
            globalState.blogPosts
        );

        if (currentBlogIndex === 0) {
            var contentFilterForPrev = { ...globalState.contentFilter };
            contentFilterForPrev.activePage--;
            globalActions.services.loadNextBlogList(
                contentFilterForPrev,
                'Prev'
            );
            setCurrentBlog(globalState.selectedBlog);
        } else if (
            currentBlogIndex > 0 &&
            currentBlogIndex <= currentBlogs.length - 1
        ) {
            globalState.selectedBlog = currentBlogs[currentBlogIndex - 1];
            setCurrentBlog(globalState.selectedBlog);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; //For chrome
            let permalink = '/blog/' + globalState.selectedBlog.slug;
            navigate(permalink, { replace: false });
        } else {
            globalState.selectedBlog = globalActions.services.loadPreviousBlog();

            setCurrentBlog(globalState.selectedBlog);

            document.body.scrollTop = 0; // For Safari

            document.documentElement.scrollTop = 0; //For chrome

            let permalink = '/blog/' + globalState.selectedBlog.slug;

            navigate(permalink, { replace: false });
        }
    }

    var blog;
    if (
        (currentBlog && currentBlog.contentTypeLabel === 'article') ||
        (currentBlog && currentBlog.contentTypeLabel === 'case study')
    ) {
        if (currentBlog.largeImage.length > 0) {
            blog = (
                <ArticleLargeImageBlog size={size} blogContent={currentBlog} />
            );
        } else {
            blog = <ArticleBlogStyled size={size} blogContent={currentBlog} />;
        }
    } else if (currentBlog && currentBlog.contentTypeLabel === 'video') {
        blog = <VideoBlog size={size} blogContent={currentBlog} />;
    } else if (currentBlog && currentBlog.contentTypeLabel === 'infographic') {
        blog = <InfographicBlog size={size} blogContent={currentBlog} />;
    }
    return (
        <BlogViewerStyled>
            <SEO title="Blogs" />
            <div className="back-nav">
                <BlogNav
                    iconPos="left"
                    variant="previous-blog-nav"
                    clickHandler={navigateback}
                    name="BACK"
                />
            </div>
            {blog}
            <div className="previous-next-social-container">
                <SocialShare size={size} />
                <BlogNav
                    iconPos="left"
                    variant="previous-blog-nav"
                    clickHandler={ShowPreviousBlog}
                    name="PREVIOUS"
                    disabled={isPrevBtnDisabled}
                />
                <BlogNav
                    iconPos="right"
                    variant="next-blog-nav"
                    clickHandler={ShowNextBlog}
                    name="NEXT"
                    disabled={isNextBtnDisabled}
                />
            </div>
            <RelatedArticles />
        </BlogViewerStyled>
    );
};

BlogViewer.propTypes = {
    size: PropTypes.object.isRequired,
};

export default withSize()(BlogViewer);
