import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

const ArticleLargeImageBlogStyled = styled.div`
    
    .article-large-img {
        height: 506px;    
        background: url(${props => props.articleBgImg}) no-repeat;
        border-top: 8px solid ${props => props.theme.colors.secondaryYellow}; 
          @media screen and (max-width: 767px) {
           margin: 0 -20px;
           width: calc(100% + 40px);
        }       

    }
    .article-card {
        display: flex;
        flex-direction: column;
        margin: -100px auto 40px auto;
        width: 100%;
        max-width: 800px;
        min-height: 339px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.14);
        background-color: ${props => props.theme.colors.white};
        border-radius: 5px;
        padding-bottom: 24px;
        @media screen and (max-width: 767px) {
            width: 100%;
            height: 304px;
        }
        h4 {
            font: bold 1rem ${props => props.theme.fonts[0]};
            color: ${props => props.theme.colors.secondaryGrey};
            text-align: center;
            line-height: 1.187rem;
            letter-spacing: normal;
            margin-top: 40px;
            small {
                font-weight: normal;
                font-size: 1rem;
                position: relative;
                text-transform: capitalize;
                :before {
                    content: '';
                    position: absolute;
                    width: 5px;
                    top: 6px;
                    left: -2px;
                    height: 14px;
                    border-right: 1px solid
                        ${props => props.theme.colors.secondaryGrey};
                }
            }
            :after {
                content: ' ';
                display: block;
                margin: 26px auto auto;
                height: 5px;
                width: 139px;
                background-color: ${props => props.theme.colors.primaryYellow};
            }
            @media screen and (max-width: 767px) {
                font: bold 0.875rem ${props => props.theme.fonts[0]};
                line-height: 1.0625rem;
            }
        }
        h3 {
            font: 500 2.25rem ${props => props.theme.fonts[1]};
            text-align: center;
            line-height: 3.25rem;
            letter-spacing: normal;
            color: ${props => props.theme.colors.secondaryGrey};
            width: 100%;
            max-width: 736px;
            margin: 40px auto auto;
            @media screen and (max-width: 767px) {
                width: 303px;
                font: bold 1.25rem ${props => props.theme.fonts[1]};
            }
            @media only screen and (width: 767px) {
                width: 400px;
                font: bold 1.25rem ${props => props.theme.fonts[1]};
            }
            @media only screen and (width: 320px) {
                width: 280px;
            }
        }
        h5 {
            font: 600 1rem ${props => props.theme.fonts[0]};
            text-align: center;
            line-height: 1.187rem;
            letter-spacing: normal;
            color: ${props => props.theme.colors.grey};
            margin-top: 24px;
            text-transform: capitalize;
            span {
                font: 1rem ${props => props.theme.fonts[0]};
                color: ${props => props.theme.colors.grey};
                letter-spacing: normal;
            }
            @media screen and (max-width: 767px) {
                font: bold 0.875rem ${props => props.theme.fonts[0]};
                line-height: 1.0625rem;
            }
        }
    }
    .photo-credit {
        font: italic 0.875rem ${props => props.theme.fonts[0]};
        float: right;
        color: ${props => props.theme.colors.grey};
        line-height: 1.0625rem;
        position: absolute;
        right: 0;
        margin-top: -35px;
        margin-right: 180px;
        @media screen and (max-width: 767px) {
            line-height: 0.875rem;
            font: italic 0.75rem ${props => props.theme.fonts[0]};
            margin-right: 20px;
        }
        @media screen and (min-width: 2000px) {
            margin-right: 600px;
        }
    }
    .article-content {
        width: 100%;
        max-width: 800px;
        margin: auto;
        font: 1.125rem ${props => props.theme.fonts[0]};
        letter-spacing: -0.43px;
        line-height: 2.125rem;

        & > p:first-of-type:first-letter {
            float: left;
            margin: 6px 8px 0 0;
            font: 700 72px ${props => props.theme.fonts[1]};
            color: ${props => props.theme.colors.primaryYellow};
            letter-spacing: 0;
            text-align: center;
            line-height: 3.875rem
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            margin: 0 0 32px 0;
        }
    }  
       
        .previous-next-nav {
            padding-bottom: 56px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            @media screen and (max-width: 767px) {
                padding-bottom: 40px;
            }
        }
    }
`;

function ArticleLargeImageBlog({ ...props }) {
    const blogContent = props && props.blogContent;
    return (
        <ArticleLargeImageBlogStyled
            articleBgImg={
                blogContent.largeImage && blogContent.largeImage[0].image[0]
            }
        >
            <div className="article-large-img" />
            <div className="article-card">
                <h4>
                    {blogContent.contentTypeLabel} &nbsp;
                    <small> &nbsp; {blogContent.publicationDate}</small>
                </h4>
                <h3>{blogContent.title}</h3>
                <h5>
                    {blogContent.author},{' '}
                    <span>{blogContent.authorOrganization}</span>
                </h5>
            </div>
            {blogContent.largeImage[0].photoCredit ? (
                <span className="photo-credit">
                    Photo:{' '}
                    {blogContent.largeImage &&
                        blogContent.largeImage[0].photoCredit}
                </span>
            ) : null}
            <div className="article-content large">
                {blogContent.body && parse(blogContent.body)}
            </div>
        </ArticleLargeImageBlogStyled>
    );
}
ArticleLargeImageBlog.propTypes = {
    blogContent: PropTypes.object.isRequired,
};
export default ArticleLargeImageBlog;
