import React, { Component } from 'react';
import styled from 'styled-components';
import BlogCard from './BlogCard';
import ContentFilter from '../ContentFilter';
import PageTitleBanner from '../PageTitleBanner';
import Pagination from '../Pagination';
import SEO from '../SEO';

const BlogHomeStyled = styled.div`
    position: relative;
    width: 100%;
    min-height: 13em;

    h3 {
        font-size: 1rem;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &--post {
            display: grid;
            align-items: center;
            justify-content: center;
            width: 98%;
            max-width: 1160px;
            grid-template-columns: 1fr;

            article:not(.featured) {
                display: flex;
                align-items: center;
            }

            @media screen and (min-width: 768px) {
                grid-template-rows: repeat(auto-fill, minmax(365px, 484px));
                grid-template-columns: repeat(2, 365px);
                grid-gap: 16px 5%;
            }

            @media screen and (min-width: 1054px) {
                width: 100%;
                grid-template-rows: 1fr repeat(auto-fill, 484px);
                grid-template-columns: repeat(3, 1fr);
                grid-column-gap: 32px;
            }

            .featured {
                display: grid;

                @media screen and (min-width: 768px) {
                    grid-area: 1 / 1 / 2 / 3;
                }

                figure {
                    @media screen and (min-width: 768px) {
                        grid-column: 1 / 2;
                    }
                }

                .post__details {
                    @media screen and (min-width: 768px) {
                        grid-column: 2 / 3;
                    }
                }

                @media screen and (min-width: 1054px) {
                    grid-area: 1 / 1 / 2 / 4;
                }
            }
        }

        @media screen and (min-width: 768px) {
            width: 96%;
            margin: 48px auto;

            .mobile-only {
                display: none;
            }

            h3 {
                align-self: flex-start;
                grid-column: 1 / 3;
            }
        }

        @media screen and (min-width: 1054px) {
            h3 {
                align-self: flex-start;
                grid-column: 1 / 4;
                font-size: 1.5rem;
            }
        }

        @media screen and (min-width: 1100px) {
            max-width: 1160px;
            margin: 48px auto;
        }
    }
`;

class BlogHome extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { postsData } = this.props;
        return (
            <BlogHomeStyled>
                <SEO title="Blogs" />
                <PageTitleBanner title="Fleet Insider By One9" />
                <ContentFilter />
                <div className="container">
                    <section className="container--post">
                        {postsData &&
                            postsData.map(post => {
                                return (
                                    <BlogCard
                                        postID={post.id}
                                        key={post.id}
                                        postSlug={post.slug}
                                        postCategory={post.contentTypeLabel}
                                        postDate={post.publicationDate}
                                        isFeatured={post.isFeatured}
                                        hasImage={
                                            (post.featuredThumbnail &&
                                                post.featuredThumbnail.length >
                                                    0) ||
                                            (post.thumbnail &&
                                                post.thumbnail.length > 0)
                                                ? true
                                                : false
                                        }
                                        postImgURL={
                                            post.isFeatured
                                                ? post.featuredThumbnail
                                                : post.thumbnail
                                        }
                                        hasVideo={post.videoUrl ? true : false}
                                        postVideoURL={post.videoUrl}
                                        postTitle={post.title}
                                        previewText={post.previewText}
                                        blog={post}
                                    />
                                );
                            })}
                        {postsData && postsData.length === 0 ? (
                            <h3>
                                No content meets your current filtering
                                criteria.
                                <br />
                                <br className="mobile-only" /> Please try a
                                different content type or broader date range.
                            </h3>
                        ) : (
                            <p></p>
                        )}
                    </section>
                </div>
                <React.Fragment>
                    <Pagination />
                </React.Fragment>
            </BlogHomeStyled>
        );
    }
}

export default BlogHome;
