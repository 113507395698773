import React from 'react';

import { withSize } from 'react-sizeme';
import Layout from '../components/Layout';
import BlogHome from '../components/Blog/BlogHome';
import { Router } from '@reach/router';
import useGlobal from '../store';
import BlogViewer from '../components/Blog/BlogViewer';

const Blog = ({ ...props }) => {
    const { size } = props;
    const [globalState] = useGlobal();
    return (
        <Layout hideNav={true} needsSolidHeader={true} size={size}>
            <Router basepath="/blog">
                <BlogHome
                    path="/"
                    postsData={globalState && globalState.blogPosts}
                />
                <BlogViewer
                    path="/*"
                    blog={globalState && globalState.selectedBlog}
                />
            </Router>
        </Layout>
    );
};
export default withSize()(Blog);
