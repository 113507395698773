import React, { useState } from 'react';
import styled from 'styled-components';
import FbIcon from '../../images/svgs/fb-icon.svg';
import TwitterIcon from '../../images/svgs/twt-icon.svg';
import MailIcon from '../../images/svgs/mail-icon.svg';
import CopyIcon from '../../images/svgs/attach-icon.svg';
import CopyCheck from '../../images/copy-indicator-check.svg';
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
} from 'react-share';

const SocialShareStyled = styled.section`
    position: relative;
    left: -0.4rem;
    max-width: 220px;

    @media screen and (max-width: 768px) {
        left: 0.5rem;
    }

    & button:focus {
        outline: none;
    }
    & button {
        margin: 0.5rem;
        img {
            border: 1px solid transparent;
            border-radius: 30px;
        }
        &:hover img {
            border: 1px solid ${props => props.theme.colors.primaryYellow};
            cursor: pointer;
        }
    }

    & .copyLink__wrapper {
        position: relative;
        display: inline-block;
        width: 37px;

        .copyLink {
            border: none;
            background: transparent;
        }

        .copy-indicator {
            position: absolute;
            top: ${props => (props.browserWidth < 375 ? '58px' : '6px')};
            left: ${props => (props.browserWidth < 375 ? '-37px' : '58px')};
            display: flex;
            justify-content: center;
            align-items: center;
            width: 134px;
            height: 41px;
            font: 16px ${props => props.theme.fonts[2]}, sans-serif;
            background-color: ${props => props.theme.colors.secondaryGrey};
            border-radius: 2px;
            color: #fff;

            &:before {
                position: absolute;
                left: ${props => (props.browserWidth < 375 ? '58px' : '-6px')};
                top: ${props => (props.browserWidth < 375 ? '-6px' : '15px')};
                content: '';
                width: 0;
                height: 0;
                border-top: ${props =>
                    props.browserWidth < 375 ? '0' : '5px solid transparent'};
                border-right: ${props =>
                    props.browserWidth < 375
                        ? `5px solid transparent`
                        : `6px solid ${props.theme.colors.secondaryGrey}`};
                border-bottom: ${props =>
                    props.browserWidth < 375
                        ? `6px solid ${props.theme.colors.secondaryGrey}`
                        : `5px solid transparent`};
                border-left: ${props =>
                    props.browserWidth < 375 ? `5px solid transparent` : 0};
            }

            .copy-check {
                margin-right: 8px;
            }
        }
    }

    @keyframes popOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;

export default function SocialShare({ size }) {
    const [showCopied, setShowCopied] = useState(false);

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setShowCopied(true);
        setTimeout(() => {
            setShowCopied(false);
        }, 3000);
    };
    return (
        <SocialShareStyled browserWidth={size.width} className="social-share">
            <FacebookShareButton url={window.location.href}>
                <img src={FbIcon} alt='facebook' />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
                <img src={TwitterIcon} alt='twitter' />
            </TwitterShareButton>
            <EmailShareButton url={window.location.href}>
                <img src={MailIcon} alt='email' />
            </EmailShareButton>
            <div className="copyLink__wrapper">
                <button
                    className="copyLink"
                    onClick={() => copyToClipboard(window.location.href)}
                >
                    <img className="copy-icon" src={CopyIcon} alt='copy' />
                </button>
                {showCopied ? (
                    <span className="copy-indicator">
                        <img
                            className="copy-check  "
                            src={CopyCheck}
                            width="16"
                            alt='copyCheck'
                        />
                        Link Copied!
                    </span>
                ) : null}
            </div>
        </SocialShareStyled>
    );
}
