import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
const ArticleBlogStyled = styled.div`
    width: 100%;
    margin: auto;
    @media only screen and (width: 1024px) {
        width: 800px;
    }
    @media screen and (max-width: 767px) {
        width: 100%;
    }

    .article-header {
        color: ${props => props.theme.colors.secondaryGrey};
        font: 500 2.5rem ${props => props.theme.fonts[1]};
        letter-spacing: 0;
        line-height: 3.625rem;
        text-align: left;
        margin: 0 0 48px 0;
        @media screen and (max-width: 767px) {
            font: 500 1.25rem ${props => props.theme.fonts[1]};
            margin: 0;
            line-height: 1.812rem;
        }
    }
    .article-content {
        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            margin: 0 0 32px 0;
            .article-small-img {
                order: 1;
            }
            .blog-type {
                order: 2;
            }
            .article-header {
                order: 3;
            }
            .author-name-company-mobile {
                order: 4;
            }
            .article-desc {
                order: 5;
            }
        }
        .left-content {
            float: left;
            @media screen and (max-width: 767px) {
                float: none;
                display: flex;
                flex-direction: column;
            }
            .article-small-img {
                height: 200px;
                width: 200px;
                margin: 0 72px 32px 0;
                @media screen and (max-width: 767px) {
                    width: 100vw;
                    margin: 0px -20px 24px -20px;
                }
            }
            h5 {
                text-align: left;
                margin-bottom: 8px;
                color: ${props => props.theme.colors.grey};
                width: 200px;
                padding-top: 16px;
                border-top: 1px solid #b3b3b3;
                font: 600 1rem ${props => props.theme.fonts[0]};
                letter-spacing: 0;
                line-height: 1.5rem;
                text-transform: capitalize;
            }
            p {
                color: ${props => props.theme.colors.grey};
                font: 0.875rem ${props => props.theme.fonts[0]};
                letter-spacing: 0;
                width: 200px;
                line-height: 1.25rem;
                padding-bottom: 16px;
                border-bottom: 1px solid #b3b3b3;
                margin-bottom: 18px;
            }
        }
        .blog-type {
            color: ${props => props.theme.colors.secondaryGrey};
            font: 1rem ${props => props.theme.fonts[0]};
            letter-spacing: 0;
            line-height: 1.187rem;
            text-align: left;
            margin-bottom: 40px;
            text-transform: capitalize;
            span {
                font-weight: bold;
                text-transform: uppercase;
            }
            @media screen and (max-width: 767px) {
                font-size: 0.875rem;
                margin: 0 20px 40px 0;
            }
        }
        .article-desc {
            color: ${props => props.theme.colors.secondaryGrey};
            font: 1.125rem ${props => props.theme.fonts[0]};
            letter-spacing: -0.43px;
            line-height: 2.125rem;
            @media screen and (max-width: 767px) {
                font: 0.875rem ${props => props.theme.fonts[0]};
                margin: 0;
                line-height: 1.625rem;
                letter-spacing: normal;
                width: 100%;
            }

            ul {
                margin: 0 0 24px 24px;

                @media screen and (min-width: 768px) {
                    margin-left: 32px;
                }

                li {
                    list-style-type: square;
                }
            }
        }
    }
    .author-name-company-mobile {
        display: none;
    }
    .what-is-one9-img {
        width: 100%;
        @media screen and (max-width: 767px) {
            width: 100vw;
            margin: 0 -20px;
        }
    }
    .photo-credit {
        font: italic 1rem ${props => props.theme.fonts[0]};
        float: right;
        color: ${props => props.theme.colors.grey};
        line-height: 1.1875rem;
        @media screen and (max-width: 767px) {
            line-height: 0.875rem;
            font: italic 0.75rem ${props => props.theme.fonts[0]};
            margin-right: 20px;
        }
    }
    .about-pilot-company-header,
    .about-life-is-why-header {
        font: 1.875rem ${props => props.theme.fonts[1]};
        text-align: left;
        margin-top: 64px;
        color: ${props => props.theme.colors.secondaryGrey};
        text-transform: uppercase;
        &::after {
            padding-bottom: 35px;
            display: block;
            content: ' ';
            width: 139px;
            margin-bottom: 23px;
            border-bottom: 5px solid
                ${props => props.theme.colors.primaryYellow};
        }
        @media screen and (max-width: 767px) {
            font: 1.5rem ${props => props.theme.fonts[1]};
            margin: 56px 20px 0 0;
        }
    }
    .about-pilot-company-desc,
    .about-life-is-why-desc {
        font: 1.125rem ${props => props.theme.fonts[0]};
        color: ${props => props.theme.colors.secondaryGrey};
        line-height: 2.125rem;
        letter-spacing: -0.43px;
        @media screen and (max-width: 767px) {
            font: 0.875rem ${props => props.theme.fonts[0]};
            margin: 0;
            line-height: 1.625rem;
            letter-spacing: normal;
        }
    }
    @media screen and (max-width: 767px) {
        .author-name-company-mobile {
            display: block;
            font: 600 0.75rem ${props => props.theme.fonts[0]};
            line-height: 1.5rem;
            color: ${props => props.theme.colors.grey};
            text-align: left;
            margin: 20px 20px 40px 0;
            text-transform: capitalize;
            span {
                font: 300 0.75rem ${props => props.theme.fonts[0]};
            }
        }
        .author-name,
        .author-company {
            display: none;
        }
    }
`;

function ArticleBlog({ ...props }) {
    const blogContent = props && props.blogContent;
    return (
        <ArticleBlogStyled>
            <section className="article-content small">
                <h1 className="article-header">{blogContent.title}</h1>
                <h5 className="author-name-company-mobile">
                    {blogContent.author},{' '}
                    <span>{blogContent.authorOrganization}</span>
                </h5>
                <div className="left-content">
                    <img
                        className="article-small-img"
                        src={
                            blogContent.smallImage[0] &&
                            blogContent.smallImage[0].image[0]
                        }
                        alt='article'
                    />
                    <h5 className="author-name">{blogContent.author}</h5>
                    <p className="author-company">
                        {blogContent.authorOrganization}
                    </p>
                </div>
                <h5 className="blog-type">
                    <span>{blogContent.contentTypeLabel}</span> |{' '}
                    {blogContent.publicationDate}
                </h5>

                {blogContent.body && parse(blogContent.body)}
            </section>
        </ArticleBlogStyled>
    );
}

export default ArticleBlog;
