import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
const VideoBlogStyled = styled.div`
    color: ${props => props.theme.colors.secondaryGrey};
    max-width: 800px;
    margin: 0 auto;
    width: 100%;

    & .previous-blog-nav {
        margin-left: 0;
        cursor: pointer;
    }
    & iframe {
        width: 100%;
        height: 205px;
        @media screen and (min-width: 768px) {
            height: 489px;
        }
    }

    & iframe + p {
        margin: 1rem 0;
        font: normal 1rem ${props => props.theme.fonts[0]};
        @media screen and (max-width: 768px) {
            font: normal 0.875rem ${props => props.theme.fonts[0]};
        }
    }
    p strong {
        text-transform: uppercase;
    }
    p b {
        text-transform: capitalize;
    }
    & h3 {
        margin: 0.5rem 0;
        text-align: left;
        font-size: 1.25rem;
        line-height: 1.8rem;
        @media screen and (min-width: 768px) {
            font-size: 1.5rem;
            line-height: 2.18rem;
        }
    }

    & article {
        font-size: 0.85rem;
        line-height: 1.65rem;
        margin: 0.5rem 0;
        font-family: ${props => props.theme.fonts[0]};
        & + p {
            margin: 1.5rem 0 0 0;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-family: ${props => props.theme.fonts[0]};
            color: ${props => props.theme.colors.grey};
        }

        @media screen and (min-width: 768px) {
            font-size: 1.125rem;
            line-height: 1.87rem;
        }
    }
`;

function VideoBlog({ ...props }) {
    const blogContent = props && props.blogContent;
    return (
        <VideoBlogStyled>
            <iframe
                src={blogContent.videoUrl && blogContent.videoUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title='picture-in-picture'
            ></iframe>
            <p>
                <strong>{blogContent.contentTypeLabel}</strong> |{' '}
                {blogContent.publicationDate}
            </p>
            <h3>{blogContent.title}</h3>
            <article>{blogContent.body && parse(blogContent.body)}</article>
            <p>
                <b>{blogContent.author} </b>, {blogContent.authorOrganization}
            </p>
        </VideoBlogStyled>
    );
}

VideoBlog.propTypes = {
    blogContent: PropTypes.shape({
        body: PropTypes.string,
        contentTypeLabel: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        videoUrl: PropTypes.string.isRequired,
        publicationDate: PropTypes.string.isRequired,
        author: PropTypes.string,
        authorOrganization: PropTypes.string,
    }),
};

export default VideoBlog;
